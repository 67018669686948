<script setup>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Link } from "@inertiajs/vue3";
import Feedback from "@/Components/NR-Feedback.vue";

</script>

<template>
    <v-layout class="rounded rounded-md">
        <v-app-bar flat color="text" class="main-header">
            <!-- <p class="text-white position-absolute" style="font-size: 32px; z-index: 1; top: 50%; left: 10%; transform: translate(-50%, -50%);">Log In</p> -->
            <img src="/static/images/login-page-image.png" alt="Application Logo" class="float-right" height="99px" width="1921px" />
        </v-app-bar>
        <v-main
            class="d-flex align-center justify-center mt-5"
            style="min-height: 480px;"
        >
            <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="4" class="px-15">
                    <Feedback></Feedback>
                    <slot />
                </v-col>
                <v-col cols="4"></v-col>
            </v-row>
        </v-main>
    </v-layout>
</template>
<script>
export default {
    data() {
        return {
            drawer: true,
            rail: true,
        };
    },
};
</script>
<style>
.main-header {
    border-bottom: 5px rgb(var(--v-theme-white)) solid;
    border-top: 5px rgb(var(--v-theme-white)) solid;
}
</style>
